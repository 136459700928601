"use client";

import { useCallback, useState } from "react";

export type Status<T = any> = {
  running: boolean;
  complete?: boolean;
  error?: Error;
  callbackResult?: T;
};

export const useTransaction = <Args extends any[], Result extends any>(
  callback: (...args: Args) => Result,
) => {
  const [status, setStatus] = useState<Status<Awaited<Result>>>({
    running: false,
    complete: false,
  });

  const handler = useCallback(
    async (...args: Args) => {
      setStatus({ running: true, complete: false });
      try {
        const callbackResult = await callback(...args);
        setStatus({
          running: false,
          complete: true,
          error: undefined,
          callbackResult: callbackResult,
        });
      } catch (error: any) {
        setStatus({ running: false, complete: false, error });
      }
    },
    [callback],
  );

  const reset = useCallback(() => {
    setStatus({ running: false, complete: false });
  }, [setStatus]);

  return [status, handler, reset] as const;
};
