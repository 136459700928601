"use client";

import classNames from "classnames";
import { ReactNode } from "react";

type spacingSize = "sm" | "md" | "lg" | "xl" | "xxl";
type alignItems = "flex-start" | "flex-end" | "center" | "stretch" | "baseline";

type Props = {
  children?: ReactNode;
  spacing?: spacingSize;
  fullWidth?: boolean;
  alignItems?: alignItems;
};

export function FlexStack({
  children,
  spacing = "md",
  fullWidth = false,
  alignItems = "flex-start",
}: Props) {
  return (
    <div
      className={classNames(
        "flex",
        "flex-col",
        spacing === "sm"
          ? "gap-sm"
          : spacing === "md"
            ? "gap-md"
            : spacing === "lg"
              ? "gap-lg"
              : spacing === "xl"
                ? "gap-xl"
                : spacing === "xxl"
                  ? "gap-xxl"
                  : "",
        fullWidth && "w-full",
        alignItems === "flex-start"
          ? "items-start"
          : alignItems === "flex-end"
            ? "items-end"
            : alignItems === "center"
              ? "items-center"
              : null,
      )}
    >
      {children}
    </div>
  );
}
